import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/detectiondatas/page', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/detectiondatas/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/detectiondatas/delete/${id}`)
}
// 导出Excel
export function exportExcel (data) {
  return request.post('/detectiondatas/exportExcel', data, {
    trim: true,
    download: true
  })
}