import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/factory/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/factory/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/factory/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/factory/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/factory/delete/batch', {
    params: {
      ids
    }
  })
}
// 查询
export function fetchTree () {
  return request.post('/factory/tree')
}