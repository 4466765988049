<template>
    <TreeSelect
      :placeholder="请选择厂家"
      :value="value"
      :data="data"
      :clearable="clearable"
      :append-to-body="appendToBody"
      :inline="inline"
      :multiple="multiple"
      :flat="multiple"
      @input="$emit('input', $event)"
    />
  </template>
  
  <script>
  import TreeSelect from '@/components/common/TreeSelect'
  import { fetchTree } from '@/api/factory'
  export default {
    name: 'ProjectSelect',
    components: { TreeSelect },
    props: {
      value: {},
      inline: {
        default: true
      },
      multiple: {
        default: false
      },
      placeholder: {
        default: ''
      },
      // 是否可清空
      clearable: {
        default: false
      },
      appendToBody: {
        default: false
      },
      // 需被排除的部门ID
      excludeId: {}
    },
    data () {
      return {
        data: []
      }
    },
    watch: {
      excludeId () {
        this.fetchData()
      }
    },
    methods: {
      /**
       * 获取所有部门
       */
      fetchData () {
        fetchTree()
          .then(records => {
            this.data = []
            this.__fillData(this.data, records)
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
      },
      /**
       * 填充部门树
       *
       * @param list 部门列表
       * @param pool 部门池
       * @private
       */
      __fillData (list, pool) {
        for (const dept of pool) {
          if (dept.id === this.excludeId) {
            continue
          }
          const deptNode = {
            id: dept.name,
            label: dept.name
          }
          list.push(deptNode)
         
        }
      }
    },
    created () {
      this.fetchData()
    }
  }
  </script>
  