<template>
  <TableLayout :permissions="['standarddatas:standarddatas:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="content" label="SBS含量" min-width="100px"></el-table-column>
        <el-table-column prop="times" label="检测次数" min-width="100px"></el-table-column>
        <el-table-column prop="result" label="计算结果" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['standarddatas:standarddatas:update', 'standarddatas:standarddatas:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaStandarddatasWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['standarddatas:standarddatas:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['standarddatas:standarddatas:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 新建/修改 -->
    <OperaStandarddatasWindow ref="operaStandarddatasWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import OperaStandarddatasWindow from '@/components/OperaStandarddatasWindow'
import { fetchList } from '@/api/standarddatas'
export default {
  name: 'Standarddatas',
  extends: BaseTable,
  components: { TableLayout, OperaStandarddatasWindow },
  data () {
    return {
      // 搜索
      searchForm: {
      }
    }
  },
  methods: {
    // 查询数据
    handlePageChange () {
      this.isWorking.search = true
      fetchList()
        .then(records => {
          this.tableData.list = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    }
  },
  created () {
    this.config({
      module: '',
      api: '/standarddatas',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
