<template>
  <TableLayout :permissions="['exception:exception:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="工程项目" prop="project">
        <el-input v-model="searchForm.project" placeholder="请输入工程项目" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="检测类型" prop="type">
        <el-input v-model="searchForm.type" placeholder="请输入检测类型" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="检测设备" prop="device">
        <el-input v-model="searchForm.device" placeholder="请输入检测设备" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['exception:exception:delete']">        
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['exception:exception:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="project" label="工程项目" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="检测类型" min-width="100px"></el-table-column>
        <el-table-column prop="except" label="预期结果" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="实际结果" min-width="100px"></el-table-column>
        <el-table-column prop="date" label="检测日期" min-width="100px"></el-table-column>
        <el-table-column prop="device" label="检测设备" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['exception:exception:update', 'exception:exception:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="push(row)" icon="el-icon-edit" v-permissions="['exception:exception:delete']">推送</el-button>
            <el-button type="text" @click="$refs.pushToUser.open(row)" icon="el-icon-user-solid" v-permissions="['exception:exception:delete']">推送指定用户</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['exception:exception:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <PushToUser ref="pushToUser" @success="handlePageChange(tableData.pagination.pageIndex)"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import PushToUser from '@/components/system/PushToUser'
import { pushText } from '@/api/exception'
export default {
  name: 'Exception',
  extends: BaseTable,
  components: { TableLayout, Pagination,PushToUser },
  data () {
    return {
      // 搜索
      searchForm: {
        project: '',
        type: '',
        device: ''
      }
    }
  },
  created () {
    this.config({
      module: '异常数据',
      api: '/exception',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods:{
    push(row)
    { 
      this.$confirm('是否推送这条异常数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          pushText(row)
          .then(records => {
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          this.$message({
            type: 'success',
            message: '推送成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消推送'
          });          
        });
     
      
    }
  }
}
</script>
