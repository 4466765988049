import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/device/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/device/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/device/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/device/delete/${id}`)
}
