<template>
  <TableLayout :permissions="['sbsreports:sbsreports:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="产品名称">
              <span>{{ props.row.productname }}</span>
            </el-form-item>
            <el-form-item label="规格型号">
              <span>{{ props.row.model }}</span>
            </el-form-item>
            <el-form-item label="送检单位">
              <span>{{ props.row.inspectionunit }}</span>
            </el-form-item>
            <el-form-item label="检测类别">
              <span>{{ props.row.detectiontype }}</span>
            </el-form-item>
            <el-form-item label="生产单位">
              <span>{{ props.row.productionunit }}</span>
            </el-form-item>
            <el-form-item label="生产日期">
              <span>{{ props.row.date }}</span>
            </el-form-item>
            <el-form-item label="样品编号">
              <span>{{ props.row.sampleid }}</span>
            </el-form-item>
            <el-form-item label="样品数量">
              <span>{{ props.row.sampleamount }}</span>
            </el-form-item>
            <el-form-item label="检测日期">
              <span>{{ props.row.detectiondate }}</span>
            </el-form-item>
            <el-form-item label="委托单编号">
              <span>{{ props.row.orderno }}</span>
            </el-form-item>
            <el-form-item label="使用工程">
              <span>{{ props.row.project }}</span>
            </el-form-item>
            <el-form-item label="使用部位">
              <span>{{ props.row.useplace }}</span>
            </el-form-item>
            <el-form-item label="检测项目">
              <span>{{ props.row.detectionproject1 }}</span>
            </el-form-item>
            <el-form-item label="主要仪器">
              <span>{{ props.row.instrument }}</span>
            </el-form-item>
            <el-form-item label="检测项目">
              <span>{{ props.row.detectionproject2 }}</span>
            </el-form-item>
            <el-form-item label="检 测 值">
              <span>{{ props.row.content }}</span>
            </el-form-item>
            <el-form-item label="备  注">
              <span>{{ props.row.remark }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column width="80px"
        label="编号"
        prop="no">
      </el-table-column>
      <el-table-column
        label="产品名称"
        prop="productName">
      </el-table-column>
      <el-table-column width="280px"
        label="使用工程"
        prop="project">
      </el-table-column>
      <el-table-column
        label="检测日期"
        prop="detectiondate">
      </el-table-column>
      <el-table-column
        label="SBS含量"
        prop="content">
      </el-table-column>
      <!-- <el-table-column label="">
        <template>
          <el-button size="small" @click="goPreview">查看</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="">
        <template slot-scope="scope">
          <el-button size="small" @click="download(scope.$index, scope.row)">下载</el-button>
        </template>
      </el-table-column>


        <!-- <el-table-column prop="no" label="编号" min-width="100px"></el-table-column>
        <el-table-column prop="productname" label="产品名称" min-width="100px"></el-table-column>
        <el-table-column prop="model" label="规格型号" min-width="100px"></el-table-column>
        <el-table-column prop="inspectionunit" label="送检单位" min-width="100px"></el-table-column>
        <el-table-column prop="detectiontype" label="检测类型" min-width="100px"></el-table-column>
        <el-table-column prop="productionunit" label="生产单位" min-width="100px"></el-table-column>
        <el-table-column prop="date" label="生产日期" min-width="100px"></el-table-column>
        <el-table-column prop="sampleid" label="样品编号" min-width="100px"></el-table-column>
        <el-table-column prop="sampleamount" label="样品数量" min-width="100px"></el-table-column>
        <el-table-column prop="detectiondate" label="检测日期" min-width="100px"></el-table-column>
        <el-table-column prop="orderno" label="委托单编号" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="使用工程" min-width="100px"></el-table-column>
        <el-table-column prop="useplace" label="使用部位" min-width="100px"></el-table-column>
        <el-table-column prop="detectionproject1" label="检测项目" min-width="100px"></el-table-column>
        <el-table-column prop="instrument" label="检测仪器" min-width="100px"></el-table-column>
        <el-table-column prop="detectionproject2" label="检测项目" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="检测值" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column> -->
        <el-table-column
          v-if="containPermissions(['sbsreports:sbsreports:update', 'sbsreports:sbsreports:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <!-- <el-button type="text" @click="$refs.operaSbsreportsWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['sbsreports:sbsreports:update']">编辑</el-button> -->
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['sbsreports:sbsreports:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaSbsreportsWindow ref="operaSbsreportsWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaSbsreportsWindow from '@/components/OperaSbsreportsWindow'
import axios from 'axios'
import { downloadLocalFile} from '@/api/system/common'
export default {
  name: 'Sbsreports',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaSbsreportsWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/sbsreports',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods:{
   
    download(index, row) {
             let params= {
                        path: row.savepath,
                        name:'C:\Users\root\Desktop\四川蜀道\报告\test.docx'
                    };
                    
                    downloadLocalFile(params)
                    .then(response => {
                    if (!response) {
                        return
                    }

                    let blob = new Blob([response.data], {
                        type: 'application/msword'
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = row.no + ".docx";
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                }).catch((error) => {
                    console.log(error);
                })
                
            }
  }
}
</script>
<style scoped>
  .demo-table-expand {
      font-size: 0;
    }
    .demo-table-expand label {
      width: 90px;
      color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
    }
    .el-table {
      width: 99.9% !important;
    }
</style>