<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" v-trim/>
      </el-form-item>
      <el-form-item label="项目所在地" prop="location">
        <el-select
          v-model="form.location"
          filterable
          allow-create
          default-first-option
          placeholder="请选择项目所在地"
          @blur="nameSelect">
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        <!-- <el-input v-model="form.location" placeholder="请输入" v-trim/> -->
      </el-form-item>
      <el-form-item label="开始时间" prop="starttime">
        <el-date-picker
          v-model="form.starttime"
          type="date"
          placeholder="选择开始日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- <el-input v-model="form.starttime" placeholder="请输入" v-trim/> -->
      </el-form-item>
      <el-form-item label="结束时间" prop="endtime">
        <el-date-picker
          v-model="form.endtime"
          type="date"
          placeholder="选择结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- <el-input v-model="form.endtime" placeholder="请输入" v-trim/> -->
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaProjectWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        location: '',
        starttime: '',
        endtime: ''
      },
      // 验证规则
      rules: {
      },
      options:
        ["成都市","资阳市","眉山市","内江市","自贡市","乐山市","泸州市","南充市","绵阳市","德阳市","宜宾市","广安市","达州市","巴中市","广元市","遂宁市","雅安市","攀枝花市","阿坝藏族羌族自治州","甘孜藏族自治州","凉山彝族自治州"]

    }
  },
  created () {
    this.config({
      api: '/project',
      'field.id': 'id'
    })
  },
  methods:{
    nameSelect(e) {
        let value = e.target.value; // 输入框值
          if(value) { 
            this.form.pkgname = value
          }
      }
  }
}
</script>
