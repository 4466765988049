<template>
  <TableLayout :permissions="['factory:factory:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="厂家名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入厂家名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['factory:factory:create', 'factory:factory:delete']">
        <li><el-button type="primary" @click="$refs.operaFactoryWindow.open('新建')" icon="el-icon-plus" v-permissions="['factory:factory:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['factory:factory:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="厂家名称" min-width="100px"></el-table-column>
        <el-table-column prop="address" label="地址" min-width="100px"></el-table-column>
        <el-table-column prop="des" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="contact" label="联系方式" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['factory:factory:update', 'factory:factory:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaFactoryWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['factory:factory:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['factory:factory:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaFactoryWindow ref="operaFactoryWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaFactoryWindow from '@/components/OperaFactoryWindow'
export default {
  name: 'Factory',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaFactoryWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        name: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/factory',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
