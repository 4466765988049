<template>
  <TableLayout :permissions="['templates:templates:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
      <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.sondata" style="width: 100%;margin-left:0px">
              <el-table-column
                v-for="(value, id, index) in sonColumns"
                :label="value.name"
                :prop="value.propName"
                :key="index"
              >
              </el-table-column>
              <el-table-column label="">
                  <template slot-scope="scope">
                      <el-button
                      size="small"
                      @click="showMap(scope.$index, scope.row)">光谱图</el-button>
                  </template> 
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="no" label="编号" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="基质沥青名称" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="工程名称" min-width="100px"></el-table-column>
        <el-table-column prop="useplace" label="使用部位" min-width="100px"></el-table-column>
        <el-table-column prop="date" label="检测日期" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['templates:templates:update', 'templates:templates:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaTemplatesWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['templates:templates:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['templates:templates:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaTemplatesWindow ref="operaTemplatesWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaTemplatesWindow from '@/components/OperaTemplatesWindow'
export default {
  name: 'Templates',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaTemplatesWindow },
  data () {
    return {
      // 搜索
      searchForm: {
      },
      sonColumns:
        [ 
          {
          name: "检测次数",
          id: "1",
          propName: "times",
          } ]
    }
  },
  created () {
    this.config({
      module: '',
      api: '/templates',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
