import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/standarddatas/page', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/standarddatas/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/standarddatas/delete/${id}`)
}
// 查询
export function fetchTree () {
  return request.post('/standarddatas/tree')
}
