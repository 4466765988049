import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/exception/page', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/exception/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/exception/delete/batch', {
    params: {
      ids
    }
  })
}
//推送
export function pushText (data) {
  return request.post('/index/pushText', data, {
    trim: true
  })
}
// 推送指定用户
export function pushToUser (data) {
  return request.post('/index/pushToUser', data)
}