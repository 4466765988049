import request from '../utils/request'

// 查询合格率
export function fetchData (data) {
  return request.post('/index/getData', data, {
    trim: true
  })
}
// 查询检测数
export function fetchList (data) {
  return request.post('/index/getDataCount', data, {
    trim: true
  })
}
// 查询地图检测数
export function fetchMap (data) {
  return request.post('/index/getDataMapCount', data, {
    trim: true
  })
}