<template>
  <TableLayout :permissions="['device:device:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="设备编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入设备编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="应用工程信息" prop="project">
        <el-input v-model="searchForm.project" placeholder="请输入应用工程信息" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="设备所在地" prop="location">
        <el-input v-model="searchForm.location" placeholder="请输入设备所在地" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="是否在使用" prop="isuse">
        <el-input v-model="searchForm.isuse" placeholder="请输入设备是否在使用" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['device:device:create']">
        <li><el-button type="primary" @click="$refs.operaDeviceWindow.open('新建')" icon="el-icon-plus" v-permissions="['device:device:create']">新建</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="no" label="设备编号" min-width="100px"></el-table-column>
        <el-table-column prop="version" label="设备软件版本" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="应用工程信息" min-width="100px"></el-table-column>
        <el-table-column prop="location" label="设备所在地" min-width="100px"></el-table-column>
        <el-table-column prop="isuse" label="设备是否在使用" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['device:device:update', 'device:device:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaDeviceWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['device:device:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['device:device:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaDeviceWindow ref="operaDeviceWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeviceWindow from '@/components/OperaDeviceWindow'
export default {
  name: 'Device',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeviceWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        project: '',
        location: '',
        isuse: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/device',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
