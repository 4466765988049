<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="样品编号" prop="no">
        <el-input v-model="form.no" placeholder="请输入样品编号" v-trim/>
      </el-form-item>
      <el-form-item label="基质沥青母样名称" prop="templatename">
        <el-input v-model="form.templatename" placeholder="请输入基质沥青母样名称" v-trim/>
      </el-form-item>
      <el-form-item label="工程名称" prop="project">
        <el-input v-model="form.project" placeholder="请输入工程名称" v-trim/>
      </el-form-item>
      <el-form-item label="" prop="useplace">
        <el-input v-model="form.useplace" placeholder="请输入" v-trim/>
      </el-form-item>
      <el-form-item label="生产日期" prop="makedate">
        <el-input v-model="form.makedate" placeholder="请输入生产日期" v-trim/>
      </el-form-item>
      <el-form-item label="检测日期" prop="date">
        <el-input v-model="form.date" placeholder="请输入检测日期" v-trim/>
      </el-form-item>
      <el-form-item label="匹配度" prop="matchingdegree" required>
        <el-input v-model="form.matchingdegree" placeholder="请输入" v-trim/>
      </el-form-item>
      <el-form-item label="厂家信息" prop="factory">
        <FactorySelect v-model="form.factory" placeholder="厂家信息" clearable/>
      </el-form-item>
      <el-form-item label="车牌信息" prop="carnumber">
        <el-input v-model="form.carnumber" placeholder="请输入车牌信息" v-trim/>
      </el-form-item>
      <!-- <el-form-item label="常规指标信息" prop="conventional">
        <el-input v-model="form.conventional" placeholder="请输入常规指标信息" v-trim/>
      </el-form-item> -->
      <el-form-item label="针入度" prop="penetration">
        <el-input v-model="form.penetration" placeholder="针入度" v-trim/>
      </el-form-item>
      <el-form-item label="软化点" prop="softpoint">
        <el-input v-model="form.softpoint" placeholder="软化点" v-trim/>
      </el-form-item>
      <el-form-item label="延度" prop="ductility">
        <el-input v-model="form.ductility" placeholder="延度" v-trim/>
      </el-form-item>
      <el-form-item label="老化后延度" prop="agingductility">
        <el-input v-model="form.agingductility" placeholder="老化后延度" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import FactorySelect from '../components/system/factory/FactorySelect.vue'
export default {
  name: 'OperaMatchingsWindow',
  extends: BaseOpera,
  components: { GlobalWindow,FactorySelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        no: '',
        templatename: '',
        project: '',
        useplace: '',
        makedate: '',
        date: '',
        matchingdegree: '',
        factory:'',
        carnumber: '',
        conventional: '',
        penetration:'',
        softpoint:'',
        ductility:'',
        agingductility:''
      },
      // 验证规则
      rules: {
        matchingdegree: [
          { required: true, message: '请输入' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/matchings',
      'field.id': 'id'
    })
  }
}
</script>
