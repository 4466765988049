<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="设备编号" prop="no">
        <el-input v-model="form.no" placeholder="请输入设备编号" v-trim/>
      </el-form-item>
      <el-form-item label="设备软件版本" prop="version">
        <el-input v-model="form.version" placeholder="请输入设备软件版本" v-trim/>
      </el-form-item>
      <el-form-item label="应用工程信息" prop="project">
        <ProjectSelect v-model="form.project" placeholder="请选择工程项目" clearable/>
        <!-- <el-input v-model="form.project" placeholder="请输入应用工程信息" v-trim/> -->
      </el-form-item>
      <el-form-item label="设备所在地" prop="location">
        <el-select
          v-model="form.location"
          filterable
          allow-create
          default-first-option
          placeholder="请选择设备所在地"
          @blur="nameSelect">
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        <!-- <el-input v-model="form.location" placeholder="请输入设备所在地" v-trim/> -->
      </el-form-item>
      <el-form-item label="设备是否在使用" prop="isuse">
        <el-input v-model="form.isuse" placeholder="请输入设备是否在使用" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ProjectSelect from '@/components/system/project/ProjectSelect'
export default {
  name: 'OperaDeviceExtWindow',
  extends: BaseOpera,
  components: { GlobalWindow, ProjectSelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        no: '',
        version: '',
        project: '',
        location: '',
        isuse: ''
      },
      // 验证规则
      rules: {
      },
      options:
        ["成都市","资阳市","眉山市","内江市","自贡市","乐山市","泸州市","南充市","绵阳市","德阳市","宜宾市","广安市","达州市","巴中市","广元市","遂宁市","雅安市","攀枝花市","阿坝藏族羌族自治州","甘孜藏族自治州","凉山彝族自治州"]

    }
  },
  created () {
    this.config({
      api: '/deviceExt',
      'field.id': 'id'
    })
  },
  methods:{
    nameSelect(e) {
        let value = e.target.value; // 输入框值
          if(value) { 
            this.form.pkgname = value
          }
      }
  }
}
</script>
