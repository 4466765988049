<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="厂家名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入厂家名称" v-trim/>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="form.address" placeholder="请输入地址" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="des">
        <el-input v-model="form.des" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="联系方式" prop="contact">
        <el-input v-model="form.contact" placeholder="请输入联系方式" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaFactoryWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        address: '',
        des: '',
        contact: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入厂家名称' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/factory',
      'field.id': 'id'
    })
  }
}
</script>
