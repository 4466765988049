<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="样品编号" prop="no">
        <el-input v-model="form.no" placeholder="请输入样品编号" v-trim/>
      </el-form-item>
      <el-form-item label="检测日期" prop="date">
        <el-input v-model="form.date" placeholder="请输入检测日期" v-trim/>
      </el-form-item>
      <el-form-item label="生产日期" prop="makedate">
        <el-input v-model="form.makedate" placeholder="请输入生产日期" v-trim/>
      </el-form-item>
      <el-form-item label="预期含量（%）" prop="except">
        <el-input v-model="form.except" placeholder="请输入预期含量（%）" v-trim/>
      </el-form-item>
      <el-form-item label="实际含量（%）" prop="content" required>
        <el-input v-model="form.content" placeholder="请输入实际含量（%）" v-trim/>
      </el-form-item>
      <el-form-item label="厂家信息" prop="factory">
        <FactorySelect v-model="form.factory" placeholder="厂家信息" clearable/>
      </el-form-item>
      <el-form-item label="车牌信息" prop="carnumber">
        <el-input v-model="form.carnumber" placeholder="请输入车牌信息" v-trim/>
      </el-form-item>
      <!-- <el-form-item label="常规指标信息" prop="conventional">
        <el-input v-model="form.conventional" placeholder="请输入常规指标信息" v-trim/>
      </el-form-item> -->

      <el-form-item label="针入度" prop="penetration">
        <el-input v-model="form.penetration" placeholder="针入度" v-trim/>
      </el-form-item>
      <el-form-item label="软化点" prop="softpoint">
        <el-input v-model="form.softpoint" placeholder="软化点" v-trim/>
      </el-form-item>
      <el-form-item label="延度" prop="ductility">
        <el-input v-model="form.ductility" placeholder="延度" v-trim/>
      </el-form-item>
      <el-form-item label="老化后延度" prop="agingductility">
        <el-input v-model="form.agingductility" placeholder="老化后延度" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import FactorySelect from '../components/system/factory/FactorySelect.vue'
export default {
  name: 'OperaDetectiondatasWindow',
  extends: BaseOpera,
  components: { GlobalWindow,FactorySelect },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        no: '',
        date: '',
        makedate: '',
        except: '',
        content: '',
        factory:'',
        carnumber: '',
        conventional: '',
        penetration:'',
        softpoint:'',
        ductility:'',
        agingductility:''
      },
      // 验证规则
      rules: {
        content: [
          { required: true, message: '请输入实际含量（%）' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/detectiondatas',
      'field.id': 'id'
    })
  }
}
</script>
