import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/project/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/project/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/project/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/project/delete/${id}`)
}
// 查询
export function fetchTree () {
  return request.post('/project/tree')
}