<template>
  <TableLayout :permissions="['matchings:matchings:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="样品编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入样品编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="母样名称" prop="templatename">
        <el-input v-model="searchForm.templatename" placeholder="请输入基质沥青母样名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" :loading="isWorking.export"  @click="exportExcel">导出</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="no" label="样品编号" min-width="100px"></el-table-column>
        <el-table-column prop="templatename" label="母样名称" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="工程名称" min-width="100px"></el-table-column>
        <!-- <el-table-column prop="useplace" label="" min-width="100px"></el-table-column> -->
        <el-table-column prop="makedate" label="生产日期" min-width="150px"></el-table-column>
        <el-table-column prop="date" label="检测日期" min-width="150px"></el-table-column>        
        <el-table-column prop="matchingdegree" label="匹配度" min-width="100px"></el-table-column>
        <!-- <el-table-column prop="templateid" label="母样id" min-width="100px"></el-table-column> -->
        <el-table-column prop="factory" label="厂家信息" min-width="100px"></el-table-column>
        <el-table-column prop="carnumber" label="车牌信息" min-width="100px"></el-table-column>
        <!-- <el-table-column prop="conventional" label="常规指标信息" min-width="100px"></el-table-column> -->
       
        <el-table-column prop="penetration" label="针入度" min-width="100px"></el-table-column>
        <el-table-column prop="softpoint" label="软化点" min-width="100px"></el-table-column>
        <el-table-column prop="ductility" label="延度" min-width="100px"></el-table-column>
        <el-table-column prop="agingductility" label="老化后延度" min-width="100px"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="showChart(scope.$index, scope.row)">光谱图</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['matchings:matchings:update', 'matchings:matchings:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaMatchingsWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['matchings:matchings:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['matchings:matchings:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        :title="title"
        width="880px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="dialogVisible">
        <div ref="myEchart" v-loading="loading" style="height: 361px; width: 100%; border: 0px solid #000"></div>
      </el-dialog>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
      
    </template>
    <!-- 新建/修改 -->
    <OperaMatchingsWindow ref="operaMatchingsWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaMatchingsWindow from '@/components/OperaMatchingsWindow'
export default {
  name: 'Matchings',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaMatchingsWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        templatename: ''
      },
      dialogVisible:false,
      loading:true,
      title: '',
    }
  },
  created () {
    this.config({
      module: '',
      api: '/matchings',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods:{
    showChart(index, row) {
        this.dialogVisible = true;
        console.log(row.cvalue);
        this.$nextTick(function () {
            setTimeout(() =>{
                this.initChart(row);
                this.loading = false
            }, 1000);
        });
    },
    initChart(row) {
      
        // let chart = this.$echarts.init(document.getElementById('myEchart'));
        let echarts = this.$echarts;
        let chart = this.$echarts.init(this.$refs.myEchart);
        let xList = row.xdata.split(",");
        let yList = row.ydata.split(",");
        let arr=this.toTwoDimension(xList,yList);
        let option = {
          xAxis: {
            type: 'value',
            name:'波数（cm-1）',
            inverse: true,
            nameLocation: 'middle',
            nameGap: 25,
            min:650,
            max:4000
          },
          yAxis: {
            type: 'value',
            name:'吸光度',
            max:0.5
            // data: [-0.1, 0, 0.25,0.5],
          },
          series: [
            {
              symbol: "none",
              data: arr,
              type: 'line',
              smooth: true
            }
          ]
        };
       
        chart.setOption(option);
    },
    toTwoDimension(arr1,arr2) {
        var arr = new Array();
        for (var i = 0; i < arr1.length; i++) {
            arr.push([arr1[i],arr2[i]]);
        }
        return arr;
    }
  }
}
</script>
