import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/matchings/page', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/matchings/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/matchings/delete/${id}`)
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/matchings/exportExcel', data, {
    trim: true,
    download: true
  })
}