<template>
  <TableLayout :permissions="['standard:standard:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="标线编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入标线编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="使用工程" prop="project">
        <el-input v-model="searchForm.project" placeholder="请输入使用工程" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceno">
        <el-input v-model="searchForm.deviceno" placeholder="请输入设备编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.sondata" style="width: 100%;margin-left:0px">
              <el-table-column
                v-for="(value, id, index) in sonColumns"
                :label="value.name"
                :prop="value.propName"
                :key="index"
              >
              </el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                    <el-button
                    size="small"
                    @click="showMap(scope.$index, scope.row)">光谱图</el-button>
                </template> 
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="no" label="标线编号" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="样品名称" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="使用工程" min-width="100px"></el-table-column>
        <el-table-column prop="useplace" label="使用部位" min-width="100px"></el-table-column>
        <el-table-column prop="detectdate" label="检测日期" min-width="100px"></el-table-column>
        <el-table-column prop="deviceno" label="设备编号" min-width="100px"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <el-button size="small" @click="showChart(scope.$index, scope.row)">线性图</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['standard:standard:update', 'standard:standard:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaStandardWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['standard:standard:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['standard:standard:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaStandardWindow ref="operaStandardWindow" @success="handlePageChange"/>
    <el-dialog
      :title="title"
      width="880px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogVisible">
      <div ref="myEchart" v-loading="loading" style="height: 361px; width: 100%; border: 0px solid #000"></div>
    </el-dialog>
    <el-dialog
        :title="title"
        width="880px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="dialogVisible1">
        <div ref="myEchart1" v-loading="loading1" style="height: 361px; width: 100%; border: 0px solid #000"></div>
      </el-dialog>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaStandardWindow from '@/components/OperaStandardWindow'
import { fetchTree } from '@/api/standarddatas'
export default {
  name: 'Standard',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaStandardWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        project: '',
        deviceno: ''
      },
      dialogVisible: false,
      dialogVisible1:false,
      detectionData: {
          r: 0.9942,
          c: 0.0611,
          d: 0.1891,
          xMin: 0.01,
          xMax: 0.06,
          yMax: 0.7,
          data: [{ x: 0.02, y: 0.3058 }, { x: 0.03, y: 0.3779 }, { x: 0.04, y: 0.44 }, { x: 0.045, y: 0.4612 }, { x: 0.05, y: 0.4905 }],
          line: [{ x: 0.02, y: 0.3113 }, { x: 0.03, y: 0.3725 }, { x: 0.04, y: 0.4335 }, {x: 0.045, y: 0.4641 }, { x: 0.05, y: 0.4946 }]
      },
      loading: true,
      loading1:true,
      title: '',
      sonColumns:
        [ //内层table的表头
          {
          name: "SBS含量",
          id: "1",
          propName: "content",
          },
          {
          name: "检测次数",
          id: "2",
          propName: "times",
          },
          {
          name: "计算结果",
          id: "3",
          propName: "result",
          } ] ,          
    }
  },
  created () {
    this.config({
      module: '',
      api: '/standard',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
    this.fetchData()
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData (){
      fetchTree()
        .then(records => {
          let list=this.tableData.list
          for (let index = 0; index < list.length; index++) {
            list[index].sondata=[];
            records.forEach(function (row){
              if(list[index].id==row.standardid)
              {                

                list[index].sondata.push(row)
              }
            })             
          }
          this.tableData.list=list
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    showChart(index, row) {
        this.dialogVisible = true;
        
        this.$nextTick(function () {
            setTimeout(() =>{
                this.initChart(row);
                this.loading = false
            }, 1000);
        });
    },
    initChart(row) {
      
        // let chart = this.$echarts.init(document.getElementById('myEchart'));
        let that=this
        let echarts = this.$echarts;
        let chart = this.$echarts.init(this.$refs.myEchart);
        let xList=[]
        that.detectionData.data=[]
        that.detectionData.line=[]
         row.sondata.forEach(function (p){
            xList.push(p.content/100)
            that.detectionData.data.push([p.content/100,p.result])
          var value=p.content*row.cvalue+row.dvalue
          that.detectionData.line.push([p.content/100,value])
         })
        let dateList = that.detectionData.data
        let lineList = that.detectionData.line

        // let xList = this.detectionData.line.map(function (item) {
        //     return [item.x];
        // });

        // let dateList = this.detectionData.data.map(function (item) {
        //     return [item.x, item.y];
        // });

        // let lineList = this.detectionData.line.map(function (item) {
        //     return [item.x, item.y];
        // });

        // console.log(xList)
        // console.log(dateList)
        // console.log(lineList)
        this.title = 'y=' + row.cvalue + 'x+' + row.dvalue + ' r²=' + row.linearvalue;

        let option = {
            title: {
                show: false,
                text: 'y=' + row.cvalue + 'x+' + row.dvalue + ' r²=' + row.linearvalue
            },
            tooltip: {
                formatter: function (params) {
                    let data = params.data || [0, 0];

                    if (params.seriesName === '含量') {
                        return '含量: ' + data[0];
                    } else {
                        let lineY = 0.0;

                        lineList.forEach(item => {
                            if (item[0] === data[0]) {
                                lineY = item[1];
                                return;
                            }
                        });
                        return '<div style="text-align: left">数据点: ' + data[1] + '<br />标准点: ' + lineY + '<div>';
                    }
                }
            },
            grid: {
                show: true,
                height: 280,
                left: '5%',
                right: '5%',
                x: 50,
                y: 25,
                x2: 30,
                y2: 35
            },
            xAxis: {
                type: 'value',
                text: '123',
                min: this.detectionData.xMin,
                max: this.detectionData.xMax,
                data: xList,
                axisLabel : {
                    show : true,
                    formatter: function(val) {
                        return val * 100 + '%';
                    }
                }
            },

            yAxis: {
                type: 'value',
                max: this.detectionData.yMax
            },
            series: [
                {
                    name: 'S1/(S1+S2)',
                    type: 'scatter',
                    symbolSize: 16,
                    data: dateList,
                    itemStyle: {
                        normal: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(120, 36, 50, 0.5)',
                            shadowOffsetY: 5,
                            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
                                offset: 0,
                                color: 'rgb(251, 118, 123)'
                            }, {
                                offset: 1,
                                color: 'rgb(204, 46, 72)'
                            }])
                        }
                    }
                },
                {
                    name: '标准线',
                    type: 'line',
                    lineStyle: {
                        width: 1.2,
                        color: '#00BFFF',
                        type: 'solid'
                    },
                    smooth: true,
                    showSymbol: false,
                    data: lineList
                }
            ]
        };

        chart.setOption(option);
    },

    showMap(index, row) {      
        this.dialogVisible1 = true;        
        this.$nextTick(function () {
            setTimeout(() =>{
                this.initChart1(row);
                this.loading1 = false
            }, 1000);
        });
    },
    initChart1(row) {
        let echarts = this.$echarts;
        let chart = this.$echarts.init(this.$refs.myEchart1);
        let xList = row.xvalue.split(",");
        let yList = row.ydata.split(",");
        let arr=this.toTwoDimension(xList,yList);
        
        let option = {
          xAxis: {
            type: 'value',
            name:'波数（cm-1）',
            inverse: true,
            nameLocation: 'middle',
            nameGap: 25,
            min:650,
            max:4000
          },
          yAxis: {
            type: 'value',
            name:'吸光度',
            max:0.5
            // data: [-0.1, 0, 0.25,0.5],
          },
          series: [
            {
              symbol: "none",
              data: arr,
              type: 'line',
              smooth: true
            }
          ]
        };
       
        chart.setOption(option);
    },
    toTwoDimension(arr1,arr2) {
        var arr = new Array();
        for (var i = 0; i < arr1.length; i++) {
            arr.push([arr1[i],arr2[i]]);
        }
        return arr;
    }
  }
}
</script>
