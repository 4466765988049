<template>
  <TableLayout>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="times" label="" min-width="100px"></el-table-column>
        <el-table-column prop="xdata" label="" min-width="100px"></el-table-column>
        <el-table-column prop="ydata" label="" min-width="100px"></el-table-column>
        <el-table-column prop="templateid" label="" min-width="100px"></el-table-column>
      </el-table>
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import { fetchList } from '@/api/templateftirdatas'
export default {
  name: 'Templateftirdatas',
  extends: BaseTable,
  components: { TableLayout },
  data () {
    return {
      // 搜索
      searchForm: {
      }
    }
  },
  methods: {
    // 查询数据
    handlePageChange () {
      this.isWorking.search = true
      fetchList()
        .then(records => {
          this.tableData.list = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    }
  },
  created () {
    this.config({
      module: '',
      api: '/templateftirdatas',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
