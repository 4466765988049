<template>
   <el-row :gutter="10">
  
  <el-col :xs="4" :sm="6" :md="8" :lg="11" :xl="11"><div class="grid-content bg-purple-light">
    
     <el-card class="box-card" :style="{width: '100%', height: '450px'}">
        <div >
            <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
                <el-form-item label="时间范围">
                    <el-date-picker
                    v-model="searchDateRange"
                    type="datetimerange"
                    range-separator="至"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    @change="handleSearchTimeChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="工程项目" prop="project">
                    <ProjectSelect v-model="searchForm.project" placeholder="请选择工程项目" clearable/>
                </el-form-item>
                <el-form-item label="厂家名称" prop="factory">
                    <FactorySelect v-model="searchForm.factory" placeholder="请选择厂家信息" clearable/>
                </el-form-item>
               
                    <el-button type="primary" @click="search">搜索</el-button>
                    <el-button @click="reset">重置</el-button>
                
            </el-form>           
        </div>
        <div  id="myChart1" :style="{width: '100%', height: '320px'}" ></div>
     </el-card>
     <el-card class="box-card" :style="{width: '100%', height: '320px',}">
      <div  id="myChart2" :style="{width: '100%', height: '320px'}"></div>
     </el-card>
    </div></el-col>
    <el-col :xs="8" :sm="6" :md="4" :lg="5" :xl="3"><div class="grid-content bg-purple"></div></el-col>
     <el-col :xs="4" :sm="6" :md="8" :lg="11" :xl="11">
        <div class="grid-content bg-purple">
     <el-card class="box-card" :style="{width: '100%', height: '320px'}">
        <div  id="myChart3" :style="{width: '100%', height: '300px'}"></div>
     </el-card>
     <el-card class="box-card" :style="{width: '100%', height: '452px'}">
        
            <div id="sc-map" ref="mapBox" style="background-color: #ffffff;"></div>
            <!-- <el-table           
                :data="tableData"
                stripe
            >
            <el-table-column prop="name" label="项目名称" min-width="100px"></el-table-column>
            <el-table-column prop="location" label="项目所在地" min-width="100px"></el-table-column>
            <el-table-column prop="starttime" label="开始时间" min-width="100px"></el-table-column>
            <el-table-column prop="endtime" label="结束时间" min-width="100px"></el-table-column>
            
            </el-table> -->
        
     </el-card>
    </div></el-col>
  <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"><div class="grid-content bg-purple-light"></div></el-col>
</el-row>
</template>

<script>
import { fetchTree } from '@/api/project'
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import ProjectSelect from '@/components/system/project/ProjectSelect'
import { fetchData,fetchList,fetchMap } from '@/api/index'
import FactorySelect from '../components/system/factory/FactorySelect.vue'
import '@/assets/js/sichuan.js'
    export default {
        name: "index",
        extends: BaseTable,
        components: { TableLayout, ProjectSelect, FactorySelect },
        data() {
            return {                
                tableData:[],
                pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
                },
              
                activeName: 'first',
                // 搜索时间反胃
                searchDateRange: [],
                searchForm: {                    
                    project: null,
                    factory:null,
                    startTime: null,
                    endTime: null
                },
                selectData:[],
                selectList:{
                    project:[],
                    total:[]
                },
                selectMap:[]
            }
        },
    created () {
        
    },
       
    mounted(){
    //   this.drawLine();
      this.getData();
    },
    methods:{
        //重置搜索
        reset () {
        this.$refs.searchForm.resetFields()
        this.searchDateRange = []
        this.searchForm.startTime = null
        this.searchForm.endTime = null
        this.searchForm.project=null
        this.searchForm.factory=null
        this.search()
        },
        //表格
        getData(){
            fetchTree()
                .then(records => {
                this.tableData = records
                })
                .catch(e => {
                this.$tip.apiFailed(e)
                })
            this.search()
        },
        handleSearchTimeChange (value) {
            this.searchForm.startTime = null
            this.searchForm.endTime = null
            if (value != null) {
                this.searchForm.startTime = value[0]
                this.searchForm.endTime = value[1]
            }
            this.search()
        },
        search(){
            fetchData(this.searchForm)
            .then(res=>{
                this.selectData=res
                this.drawLine()
            })
            .catch(e => {
                this.$tip.apiFailed(e)
                })

            fetchList(this.searchForm)
            .then(res=>{       
                this.selectList.project=[]
                this.selectList.total=[]
                for (const dept of res) {
                    
                    this.selectList.project.push(dept.project)
                    this.selectList.total.push(dept.total)
                }     
                // console.log(this.selectList.project)
                // console.log(this.selectList.total)
                this.drawTable()
            })
            .catch(e => {
                this.$tip.apiFailed(e)
            })


            fetchMap(this.searchForm)
            .then(res=>{       
                this.selectMap=[]
                for (const dept of res) {
                    const deptNode = {
                    name: dept.location,
                    value: parseInt(dept.total)
                    }
                    this.selectMap.push(deptNode)
                }
                console.log(this.selectMap)
                this.drawMap()
            })
            .catch(e => {
                this.$tip.apiFailed(e)
            })
            
        },
       
      drawLine(){
        // 基于准备好的dom，初始化echarts实例
        let myChart1 = this.$echarts.init(document.getElementById('myChart1'))
        let myChart2 = this.$echarts.init(document.getElementById('myChart2'))
       
        // 绘制图表
        myChart1.setOption({
            title: { text: '改性沥青样品合格率' },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'},
            legend: {
                orient: 'vertical',
                top:30,
                left: 10,
                data: ['与实际含量相差≤±0.2%', '与实际含量相差>±0.2%']
            },
            series : [
                {
                    name: '合格率',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '24',
                            fontWeight: 'bold',
                            color:'#000'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: this.selectData.sbsQualified, name: '与实际含量相差≤±0.2%'},
                        {value: this.selectData.sbsNoQualified, name: '与实际含量相差>±0.2%'}
                    ]
                }
            ]
        });

        myChart2.setOption({
            title: { text: '基质沥青样品合格率' },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'},
            legend: {
                orient: 'vertical',
                top:30,
                left: 10,
                data: ['匹配度>94', '匹配度≤94']
            },
            series : [
                {
                    name: '合格率',
                    type: 'pie',
                    radius: ['50%','70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '24',
                            fontWeight: 'bold',
                            color:'#000'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: this.selectData.matrixQualified, name: '匹配度>94',itemStyle:{color:'#5470c6'}},
                        {value: this.selectData.matrixNoQualified, name: '匹配度≤94',itemStyle:{color:'#ee6666'}}
                    ]
                    }
                ]
            });
        
        },
        drawTable(){
            let myChart3 = this.$echarts.init(document.getElementById('myChart3'))
            myChart3.setOption({
                title: { text: '设备使用情况' },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },    
                xAxis: {
                    type: 'category',
                    data: this.selectList.project,
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                    data: this.selectList.total,
                    type: 'bar',
                    barWidth: '60%',
                    color:'#5470c6'
                    }
                ]
                });
            },
        drawMap(){
            let chart4 = this.$echarts.init(this.$refs.mapBox)
            chart4.setOption({
                // 注释部分是图的标题，按需要进行修改
                title: {
                    text: '各市设备使用情况',
                    left: 'center',
                    subtext: '',
                    padding: [15, 15],
                    textStyle: {
                    fontFamily: '微软雅黑',
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: '#55aaff'
                    }
                },
                tooltip: {
                trigger: 'item',
                showDelay: 0,
                transitionDuration: 0.2
                },                  
                series: [
                    {
                    map: '四川',
                    type: 'map',
                    mapType: '四川',
                    name: '各市设备检测数量',
                    aspectScale: 0.9,
                    roam: true,
                    label: {
                        show: true,
                        textStyle: {
                        color: '#fff',
                        fontSize: 12,
                        }
                    },
                    data: this.selectMap,
                    itemStyle: {
                        normal: {
                        // 图形的描边颜色
                        borderColor: '#55aaff',
                        // 描边线宽。
                        borderWidth: 1, 
                        // 柱条的描边类型。
                        borderType: 'solid', 
                        // 图形的颜色 #233F53
                        areaColor: '#f1f1f1', 
                        // 图形阴影的模糊大小。
                        shadowBlur: 5,
                        // 阴影色 #233F53
                        shadowColor: '#cecece', 
                        // X轴阴影
                        shadowOffsetX: 5, 
                        // Y轴阴影
                        shadowOffsetY: 5,
                        label: {
                            // 显示省份下面市、州的名称
                            show: true,
                            textStyle: {
                            color: '#000000',
                            fontSize: 10,
                            },
                        }
                        },
                        // 鼠标放上去后，样式改变
                        emphasis: {
                        // 图形的描边颜色
                        borderColor: '#2378f7', 
                        borderWidth: '1',
                        // 阴影色
                        areaColor: '#55aaff',
                        label: {
                            show: true,
                            textStyle: {
                            color: '#000000',
                            fontSize: 10,
                            }
                        }
                        },
                        effect: {
                        show: true,
                        shadowBlur: 10,
                        loop: true,
                        }
                    }
                    
                    }
                ]
            })
        }
        }
    }
</script>

<style scoped>
 .text {
    font-size: 18px;
  }
  #sc-map{
	width: 100%;
	height: 430px;
}
</style>