<template>
    <GlobalWindow
      :visible.sync="visible"
      :confirm-working="isWorking"
      width="700px"
      title="选择异常推送用户"
      @confirm="confirm"
    >      
      <el-transfer width="500px"
        v-model="selectedIds"        
        filterable
        :titles="['未选择的用户', '已选择的用户']"
        :props="{
          key: 'id',
          label: 'realname'
        }"
        :data="roles">
      </el-transfer>
      <!-- <p class="tip" >远程连接</p>
      <el-table :data="tableData">
        
        <el-table-column prop="name" label="电脑名" min-width="100px"></el-table-column>
        <el-table-column          
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="mstsc(row)" icon="el-icon-s-platform" >远程连接</el-button>
          </template>
        </el-table-column>
      </el-table> -->

    </GlobalWindow>
  </template>
  
  <script>
  import BasePage from '@/components/base/BasePage'
  import GlobalWindow from '@/components/common/GlobalWindow' 
  import { fetchTree } from '@/api/system/user'
  import { pushToUser } from '@/api/exception'
  export default {
    name: 'Window',
    extends: BasePage,
    components: { GlobalWindow },
    data () {
      return {
        visible: false,
        isWorking: false,
        
        user: null,
        // 用户列表
        roles: null,
        // 已选中的用户ID
        selectedIds: [],
        tableData:[]
      }
    },
    methods: {
      /**
       * 打开窗口
       *
       * @param user 
       */
      open (user) {        
        fetchTree()
          .then(records => {
            this.roles=records
            this.user = user
            
            // this.__fillData(this.roles, records)
           
            this.visible = true
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
      },
      
      /**
       * 确认选择角色
       */
      confirm () {
        if (this.isWorking) {
          return
        }
        this.isWorking = true
        pushToUser({
          userId: this.user.id,
          roleIds: this.selectedIds
        })
          .then(() => {
            this.visible = false
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      },
      // 关闭
      close () {
        this.$emit('update:visible', false)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/style/variables.scss";
  // 角色配置
  .global-window {
    .tip {
      em {
        font-style: normal;
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
  .edit_dev >>> 
   .el-transfer-panel__list{
    width:350px;
     height: 500px;
   }
  </style>
  
  