<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="标线编号" prop="no">
        <el-input v-model="form.no" placeholder="请输入标线编号" v-trim/>
      </el-form-item>
      <el-form-item label="样品名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入样品名称" v-trim/>
      </el-form-item>
      <el-form-item label="使用工程" prop="project">
        <el-input v-model="form.project" placeholder="请输入使用工程" v-trim/>
      </el-form-item>
      <el-form-item label="使用部位" prop="useplace">
        <el-input v-model="form.useplace" placeholder="请输入使用部位" v-trim/>
      </el-form-item>
      <el-form-item label="检测日期" prop="detectdate">
        <el-input v-model="form.detectdate" placeholder="请输入检测日期" v-trim/>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceno">
        <el-input v-model="form.deviceno" placeholder="请输入设备编号" v-trim/>
      </el-form-item>
      <el-form-item label="线性值" prop="linearvalue">
        <el-input v-model="form.linearvalue" placeholder="请输入线性值" v-trim/>
      </el-form-item>
      <el-form-item label="C值" prop="cvalue">
        <el-input v-model="form.cvalue" placeholder="请输入C值" v-trim/>
      </el-form-item>
      <el-form-item label="D值" prop="dvalue">
        <el-input v-model="form.dvalue" placeholder="请输入D值" v-trim/>
      </el-form-item>
      <el-form-item label="上传日期" prop="uploaddate">
        <el-input v-model="form.uploaddate" placeholder="请输入上传日期" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaStandardWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        no: '',
        name: '',
        project: '',
        useplace: '',
        detectdate: '',
        deviceno: '',
        linearvalue: '',
        cvalue: '',
        dvalue: '',
        uploaddate: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/standard',
      'field.id': 'id'
    })
  }
}
</script>
