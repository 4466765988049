<template>
  <TableLayout v-permissions="['deviceext:deviceext:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="设备编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入设备编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="设备所在地" prop="location">
        <el-input v-model="searchForm.location" placeholder="请输入设备所在地" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="设备是否在使用" prop="isuse">
        <el-input v-model="searchForm.isuse" placeholder="请输入设备是否在使用" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="" prop="projectName">
        <el-input v-model="searchForm.projectName" placeholder="请输入" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['deviceext:deviceext:create', 'deviceext:deviceext:delete']">
        <li><el-button type="primary" @click="$refs.operaDeviceExtWindow.open('新建')" icon="el-icon-plus" v-permissions="['deviceext:deviceext:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['deviceext:deviceext:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="no" label="设备编号" min-width="100px"></el-table-column>
        <el-table-column prop="version" label="设备软件版本" min-width="100px"></el-table-column>
        <el-table-column prop="location" label="设备所在地" min-width="100px"></el-table-column>
        <el-table-column prop="isuse" label="设备是否在使用" min-width="100px"></el-table-column>
        <el-table-column prop="project.name" label="" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['deviceext:deviceext:update', 'deviceext:deviceext:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaDeviceExtWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['deviceext:deviceext:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['deviceext:deviceext:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaDeviceExtWindow ref="operaDeviceExtWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeviceExtWindow from '@/components/OperaDeviceExtWindow'
export default {
  name: 'DeviceExt',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeviceExtWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        location: '',
        isuse: '',
        projectName: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/deviceExt',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
