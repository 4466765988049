import request from '../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/templateftirdatas/page', data, {
    trim: true
  })
}
// 查询
export function fetchTree () {
  return request.post('/templateftirdatas/tree')
}
