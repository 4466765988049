<template>
  <TableLayout :permissions="['project:project:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="项目所在地" prop="location">
        <el-input v-model="searchForm.location" placeholder="请输入项目所在地" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['project:project:create']">
        <li><el-button type="primary" @click="$refs.operaProjectWindow.open('新建')" icon="el-icon-plus" v-permissions="['project:project:create']">新建</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="name" label="项目名称" min-width="100px"></el-table-column>
        <el-table-column prop="location" label="项目所在地" min-width="100px"></el-table-column>
        <el-table-column prop="starttime" label="开始时间" min-width="100px"></el-table-column>
        <el-table-column prop="endtime" label="结束时间" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['project:project:update', 'project:project:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaProjectWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['project:project:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['project:project:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaProjectWindow ref="operaProjectWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectWindow from '@/components/OperaProjectWindow'
export default {
  name: 'Project',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaProjectWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        name: '',
        location: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/project',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
