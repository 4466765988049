<template>
  <TableLayout v-permissions="['templatesext:templatesext:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="编号" prop="no">
        <el-input v-model="searchForm.no" placeholder="请输入编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="工程名称" prop="project">
        <el-input v-model="searchForm.project" placeholder="请输入工程名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceNo">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <!-- <ul class="toolbar" v-permissions="['templatesext:templatesext:create', 'templatesext:templatesext:delete']">
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['templatesext:templatesext:delete']">删除</el-button></li>
      </ul> -->
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.sondata" style="width: 100%;margin-left:0px">
              <el-table-column
                v-for="(value, id, index) in sonColumns"
                :label="value.name"
                :prop="value.propName"
                :key="index"
              >
              </el-table-column>
              <el-table-column label="">
                  <template slot-scope="scope">
                      <el-button
                      size="small"
                      @click="showChart(scope.$index, scope.row)">光谱图</el-button>
                  </template> 
                  </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="no" label="编号" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="基质沥青名称" min-width="100px"></el-table-column>
        <el-table-column prop="project" label="工程名称" min-width="100px"></el-table-column>
        <!-- <el-table-column prop="xdata" label="" min-width="100px"></el-table-column>
        <el-table-column prop="ydata" label="" min-width="100px"></el-table-column> -->
        <el-table-column prop="date" label="检测日期" min-width="100px"></el-table-column>
        <el-table-column prop="device.no" label="设备编号" min-width="100px"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="showChart(scope.$index, scope.row)">光谱图</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['templatesext:templatesext:update', 'templatesext:templatesext:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <!-- <el-button type="text" @click="$refs.operaTemplatesExtWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['templatesext:templatesext:update']">编辑</el-button> -->
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['templatesext:templatesext:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
      <el-dialog
        :title="title"
        width="880px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="dialogVisible">
        <div ref="myEchart" v-loading="loading" style="height: 361px; width: 100%; border: 0px solid #000"></div>
      </el-dialog>
    </template>
    <!-- 新建/修改 -->
    <OperaTemplatesExtWindow ref="operaTemplatesExtWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaTemplatesExtWindow from '@/components/OperaTemplatesExtWindow'
import { fetchTree } from '@/api/templateftirdatas'
export default {
  name: 'TemplatesExt',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaTemplatesExtWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        project: '',
        deviceNo: ''
      },
      sonColumns:
      [ 
        {
        name: "检测次数",
        id: "1",
        propName: "times",
        } ],
      dialogVisible:false,
      loading:true,
      title: '',
    }
  },
  created () {
    this.config({
      module: '',
      api: '/templatesExt',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
    this.fetchData()
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData (){
      fetchTree()
      .then(records => {
        let list=this.tableData.list
        for (let index = 0; index < list.length; index++) {
          list[index].sondata=[];
          records.forEach(function (row){
            if(list[index].id==row.templateid)
            {                

              list[index].sondata.push(row)
            }
          })             
        }
        this.tableData.list=list
      })
      .catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    showChart(index, row) {
        this.dialogVisible = true;
        console.log(row.cvalue);
        this.$nextTick(function () {
            setTimeout(() =>{
                this.initChart(row);
                this.loading = false
            }, 1000);
        });
    },
    initChart(row) {
      
        // let chart = this.$echarts.init(document.getElementById('myEchart'));
        let echarts = this.$echarts;
        let chart = this.$echarts.init(this.$refs.myEchart);
        let xList = row.xdata.split(",");
        let yList = row.ydata.split(",");
        let arr=this.toTwoDimension(xList,yList);
        let option = {
          xAxis: {
            type: 'value',
            name:'波数（cm-1）',
            inverse: true,
            nameLocation: 'middle',
            nameGap: 25,
            min:650,
            max:4000
          },
          yAxis: {
            type: 'value',
            name:'吸光度',
            max:0.5
            // data: [-0.1, 0, 0.25,0.5],
          },
          series: [
            {
              symbol: "none",
              data: arr,
              type: 'line',
              smooth: true
            }
          ]
        };
       
        chart.setOption(option);
    },
    toTwoDimension(arr1,arr2) {
        var arr = new Array();
        for (var i = 0; i < arr1.length; i++) {
            arr.push([arr1[i],arr2[i]]);
        }
        return arr;
    }
  }
}
</script>
